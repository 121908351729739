import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Button, Modal, Box, IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

function App() {

  const [openPopUpD, setOpenPopUpD] = useState(false);
  const [openPopUpF, setOpenPopUpF] = useState(false);
  const [openPopUpA, setOpenPopUpA] = useState(false);
  const [openPopUpG, setOpenPopUpG] = useState(false);
  const [openPopUpU, setOpenPopUpU] = useState(false);
  const [openPopUpAU, setOpenPopUpAU] = useState(false);
  const [openPopUpM, setOpenPopUpM] = useState(false);
  const [openPopUpRT, setOpenPopUpRT] = useState(false);
  const [openPopUpSH, setOpenPopUpSH] = useState(false);

  const handleOpenPopUpD = () => setOpenPopUpD(true);
  const handleClosePopUpD = () => setOpenPopUpD(false);
  const handleOpenPopUpF = () => setOpenPopUpF(true);
  const handleClosePopUpF = () => setOpenPopUpF(false);
  const handleOpenPopUpA = () => setOpenPopUpA(true);
  const handleClosePopUpA = () => setOpenPopUpA(false);
  const handleOpenPopUpG = () => setOpenPopUpG(true);
  const handleClosePopUpG = () => setOpenPopUpG(false);
  const handleOpenPopUpU = () => setOpenPopUpU(true);
  const handleClosePopUpU = () => setOpenPopUpU(false);
  const handleOpenPopUpAU = () => setOpenPopUpAU(true);
  const handleClosePopUpAU = () => setOpenPopUpAU(false);
  const handleOpenPopUpM = () => setOpenPopUpM(true);
  const handleClosePopUpM = () => setOpenPopUpM(false);
  const handleOpenPopUpRT = () => setOpenPopUpRT(true);
  const handleClosePopUpRT = () => setOpenPopUpRT(false);
  const handleOpenPopUpSH = () => setOpenPopUpSH(true);
  const handleClosePopUpSH = () => setOpenPopUpSH(false);

  return (
    <div className="container">
      <div className="left-side">
        <div className="name-title">
          <h1 style={{ color: 'white', fontSize: '50px' }}>João Palma</h1>
        </div>
        <div className="contact-info">
          <a href="mailto:joao4palma@gmail.com" className="contact-item">
            <img src={`${process.env.PUBLIC_URL}/images/email.png`} alt="Email" />
            <span>joao4palma@gmail.com</span>
          </a>
          <a href="https://www.linkedin.com/in/joaomsspalma/" className="contact-item" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/images/linkedin.png`} alt="LinkedIn" />
            <span>joaomsspalma</span>
          </a>
          <a href="https://github.com/JoaoPalmaFCT" className="contact-item" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/images/github.png`} alt="GitHub" />
            <span>JoaoPalmaFCT</span>
          </a>
          <a href="https://orcid.org/0009-0009-2797-7667" className="contact-item" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/images/orcid.png`} alt="ORCID" />
            <span>0009-0009-2797-7667</span>
          </a>
          <a href={`${process.env.PUBLIC_URL}/cv-JoaoPalma.pdf`} className="contact-item" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/images/file.png`} alt="CV" />
            <span>CV</span>
          </a>
        </div>
      </div>
      <div className="right-side">
        <div className="right-side-content">
          <section>
            <div className="section-title">
              <h2>Education</h2>
            </div>
            <div className="timeline">
              <div className="timeline-item">
                <div className="dot-line">
                  <div className="dot" />
                </div>
                <div className="timeline-content">
                  <p className="institution-name">Faculdade de Ciências e Tecnologia da Universidade NOVA de Lisboa</p>
                  <p className="degree-title">Master's degree</p>
                  <p className="degree-field">Computer Science and Engineering</p>
                  <p className="degree-dates">2022-Present (Awaiting thesis defense)</p>
                  <div className="course-list" style={{ paddingLeft: '20px' }}>
                    <ul>
                      <li>Cloud Computing Systems</li>
                      <li>Games and Simulation</li>
                      <li>Internet Application Design and Implementation</li>
                      <li>Machine Learning</li>
                      <li>Network and Computer Systems Security</li>
                      <li>Systems and Process Modelling</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="timeline-item">
                <div className="dot-line">
                  <div className="dot" />
                </div>
                <div className="timeline-content">
                  <p className="institution-name">Faculdade de Ciências e Tecnologia da Universidade NOVA de Lisboa</p>
                  <p className="degree-title">Bachelor's degree</p>
                  <p className="degree-field">Computer Science and Engineering</p>
                  <p className="degree-dates">2019-2022</p>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="section-title">
              <h2>Experience and Projects</h2>
            </div>
            <div className="timeline">
              <div className="timeline-item">
                <div className="dot-line">
                  <div className="dot" />
                </div>
                <div className="timeline-content">
                  <p className="project-name">Dissertation</p>
                  <p className="project-description">“Development of a Web Interface for an Inclinometer Monitoring System”</p>
                  <div className="project-list" style={{ paddingBottom: '10px' }}>
                    <p>Development of a web interface for a low-cost inclinometer monitoring system prototype, within the scope of a research protocol with Laboratório Nacional de Engenharia Civil (LNEC), including the following main highlights:</p>
                    <ul>
                      <li>Web Interface for graphical analysis using React</li>
                      <li>Backend development using Spring and Kotlin</li>
                      <li>Management of different types of databases: time-series(InfluxDB), relational (PostgreSQL) and NoSQL cloud (Google Cloud Firestore)</li>
                      <li>Integration with the existing inclinometer system</li>
                    </ul>
                  </div>
                  <div className="button-details">
                    <Button sx={{
                      paddingTop: '10px',
                      backgroundColor: '#059669',
                      '&:hover': {
                        backgroundColor: '#064e3b',
                      },
                    }} variant="contained" onClick={handleOpenPopUpD}>View more details</Button>
                  </div>
                </div>
              </div>
              <Modal
                open={openPopUpD}
                onClose={handleClosePopUpD}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {
                      xs: '90%', // Mobile screens
                      sm: '80%', // Smaller screens
                      md: '70%', // Default
                    },
                    maxHeight: {
                      xs: '80vh',
                      sm: '85vh',
                    },
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: {
                      xs: 2,
                      sm: 3,
                      md: 4,
                    },
                    px: {
                      md: 10,
                    },
                    borderRadius: 2,
                    overflow: 'auto',
                    '& .close-button': {
                      position: 'absolute',
                      top: 8,
                      right: 8,
                    },
                  }}>
                  <IconButton
                    className="close-button"
                    aria-label="close"
                    onClick={handleClosePopUpD}>
                    <Clear />
                  </IconButton>
                  <div>
                    <p className="diss-title">Development of a Web Interface for an Inclinometer Monitoring System</p>
                    <div className="project-list" style={{ paddingBottom: '10px' }}>
                      <p>&nbsp;&nbsp;The dissertation is included in a comprehensive project, within the scope of a research
                        protocol with Laboratório Nacional de Engenharia Civil (LNEC).</p>
                      <p>&nbsp;&nbsp;The fulfilment of the project is based on an existing data collection system from
                        sensors embedded in a low-cost inclinometer monitoring system prototype, known as
                        LINCS, which served as the starting point for the development of the dissertation.
                        The processing of data gathered by the sensors is essential and its analysis provides a
                        structured method for evaluating and overseeing subsurface characteristics and structural safety.</p>
                      <p>&nbsp;&nbsp;The project culminated in the development of a web application designed to
                        manage, store and visualize data collected by sensors in inclinometers in a concise and
                        intuitive manner. The application aims to address the lacking features from existing
                        solutions, enhancing the produced visualizations, by offering an interactive, simple,
                        and intuitive interface that addresses the specific needs of inclinometer monitoring.</p>
                      <p>&nbsp;&nbsp;The web application aspires to stand out from existing solutions, providing key
                        features that include the management of monitoring groups and profiles, interactive
                        graph visualization, data filtering options and the capability to view and rectify profiles
                        based on images. By focusing on usability and functionality on a set of continuous
                        data sources, this project intended to facilitate more accurate and timely decision-making, ultimately contributing to improved safety and performance in inclinometer monitoring analysis.</p>
                      <p>The main objectives for the thesis were:</p>
                      <ul>
                        <li>Study and extension of the architecture of the LINCS.</li>
                        <li>Definition of the architecture that supports the web interface for the existing system.</li>
                        <li>Development of a prototype for a web interface to enable interactive graphical
                          analysis.</li>
                        <li>Validation of the prototype through various approaches, testing different aspects of
                          the integration</li>
                      </ul>
                      <p>Main technologies:</p>
                      <ul>
                        <li>Programming Languages: Kotlin, TypeScript</li>
                        <li>Frameworks and Libraries: Spring, React, Recharts, Konva, Tailwind CSS, Material-UI, Node.js</li>
                        <li>Databases: InfluxDB, PostgreSQL, Google Cloud Firestore</li>
                        <li>APIs and Services: OpenStreetMap</li>
                        <li>Development and Testing Tools: Postman, OpenAPI</li>
                      </ul>
                    </div>
                    <Slide
                      autoplay={true}
                      onChange={function noRefCheck() { }}
                      onStartChange={function noRefCheck() { }}
                    >
                      <div className="each-slide-effect">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}/mockups/thesis/Sistema.png`} alt={'1'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}/mockups/thesis/results1.png`} alt={'2'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}/mockups/thesis/results2.png`} alt={'3'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}/mockups/thesis/results3.png`} alt={'4'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}/mockups/thesis/groups1.png`} alt={'5'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}/mockups/thesis/profiles1.png`} alt={'6'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}/mockups/thesis/profiles2.png`} alt={'7'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}/mockups/thesis/profiles3.png`} alt={'8'} />
                        </div>
                      </div>
                    </Slide>
                  </div>
                </Box>
              </Modal>
              <div className="timeline-item">
                <div className="dot-line">
                  <div className="dot" />
                </div>
                <div className="timeline-content">
                  <p className="project-name">EPIA 2024 Conference Paper</p>
                  <p className="project-description">“BI-Enabled Web Visualization of In-Place Inclinometer Data for Geotechnical Applications”</p>
                  <div className="project-list" style={{ paddingBottom: '10px' }}>
                    <p>Contribution to the EPIA 2024 conference in the “Knowledge Discovery and Business Intelligence” category, with the paper published by Springer in the book “Progress in Artificial Intelligence”.</p>
                  </div>
                  <div className="button-details">
                    <Button sx={{
                      paddingTop: '10px',
                      backgroundColor: '#059669',
                      '&:hover': {
                        backgroundColor: '#064e3b',
                      },
                    }} variant="contained" onClick={() => window.open('https://link.springer.com/book/9783031735028', '_blank')}>View book details</Button>
                  </div>
                </div>
              </div>
              <div className="timeline-item">
                <div className="dot-line">
                  <div className="dot" />
                </div>
                <div className="timeline-content">
                  <p className="project-name">Engineering and Innovation Project</p>
                  <p className="project-description">“Forgest”</p>
                  <div className="project-list" style={{ paddingBottom: '10px' }}>
                    <p>Development of an open platform, accessible on both web and mobile environments, supported by cloud services (Google Cloud), where property owners can voluntarily register their interest and verify ownership of their plots as part of a community management system.</p>
                  </div>
                  <div className="button-details">
                    <Button sx={{
                      paddingTop: '10px',
                      backgroundColor: '#059669',
                      '&:hover': {
                        backgroundColor: '#064e3b',
                      },
                    }} variant="contained" onClick={handleOpenPopUpF}>View more details</Button>
                  </div>
                </div>
              </div>
              <Modal
                open={openPopUpF}
                onClose={handleClosePopUpF}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {
                      xs: '90%', // Mobile screens
                      sm: '80%', // Smaller screens
                      md: '70%', // Default
                    },
                    maxHeight: {
                      xs: '80vh',
                      sm: '85vh',
                    },
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: {
                      xs: 2,
                      sm: 3,
                      md: 4,
                    },
                    px: {
                      md: 10,
                    },
                    borderRadius: 2,
                    overflow: 'auto',
                    '& .close-button': {
                      position: 'absolute',
                      top: 8,
                      right: 8,
                    },
                  }}>
                  <IconButton
                    className="close-button"
                    aria-label="close"
                    onClick={handleClosePopUpF}>
                    <Clear />
                  </IconButton>
                  <div>
                    <p className="diss-title">Forgest</p>
                    <div className="project-list" style={{ paddingBottom: '10px' }}>
                      <p>The objective of this project was to develop an open and inclusive technology platform designed to support a multimodal application accessible on both web and mobile environments. The platform utilizes cloud computing and data management services, allowing property owners to voluntarily indicate their interest in joining the initiative and to verify their ownership of parcels.</p>
                      <p>A key feature of the platform is its communication capabilities, aimed at engaging communities and motivating participation.</p>
                      <p>Main technologies:</p>
                      <ul>
                        <li>Programming Languages: Java, JavaScript</li>
                        <li>Frameworks and Libraries: Bootstrap</li>
                        <li>APIs and Services: Google Cloud, Google App Engine</li>
                        <li>Development and Testing Tools: Postman</li>
                      </ul>
                    </div>
                    <Slide
                      autoplay={true}
                      onChange={function noRefCheck() { }}
                      onStartChange={function noRefCheck() { }}
                    >
                      <div className="each-slide-effect">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}/mockups/forgest/forgest1.jpg`} alt={'1'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}/mockups/forgest/forgest2.jpg`} alt={'2'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img className="mobile-image" src={`${process.env.PUBLIC_URL}/mockups/forgest/forgest1_app.jpg`} alt={'3'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img className="mobile-image" src={`${process.env.PUBLIC_URL}/mockups/forgest/forgest2_app.jpg`} alt={'4'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img className="mobile-image" src={`${process.env.PUBLIC_URL}/mockups/forgest/forgest3_app.jpg`} alt={'5'} />
                        </div>
                      </div>
                    </Slide>
                  </div>
                </Box>
              </Modal>
              <div className="timeline-item">
                <div className="dot-line">
                  <div className="dot" />
                </div>
                <div className="timeline-content">
                  <p className="project-name">Apartment Bookings Management Project</p>
                  <p className="project-description">“Bookings Application”</p>
                  <div className="project-list" style={{ paddingBottom: '10px' }}>
                    <p>Development of a full-stack application to manage apartment bookings. The implementation includes a RESTful API with Spring and Kotlin, documented in OpenAPI, and a React/Redux client interface in TypeScript. Key features included reservation management, calendar availability, and review system integration.</p>
                  </div>
                  <div className="button-details">
                    <Button sx={{
                      paddingTop: '10px',
                      backgroundColor: '#059669',
                      '&:hover': {
                        backgroundColor: '#064e3b',
                      },
                    }} variant="contained" onClick={handleOpenPopUpA}>View more details</Button>
                  </div>
                </div>
              </div>
              <Modal
                open={openPopUpA}
                onClose={handleClosePopUpA}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {
                      xs: '90%', // Mobile screens
                      sm: '80%', // Smaller screens
                      md: '70%', // Default
                    },
                    maxHeight: {
                      xs: '80vh',
                      sm: '85vh',
                    },
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: {
                      xs: 2,
                      sm: 3,
                      md: 4,
                    },
                    px: {
                      md: 10,
                    },
                    borderRadius: 2,
                    overflow: 'auto',
                    '& .close-button': {
                      position: 'absolute',
                      top: 8,
                      right: 8,
                    },
                  }}>
                  <IconButton
                    className="close-button"
                    aria-label="close"
                    onClick={handleClosePopUpA}>
                    <Clear />
                  </IconButton>
                  <div>
                    <p className="diss-title">Bookings Application</p>
                    <div className="project-list" style={{ paddingBottom: '10px' }}>
                      <p>&nbsp;&nbsp;Design and implementation of a full-stack apartment management system to streamline booking, availability, and review processes.</p>
                      <p>&nbsp;&nbsp;The system supports three main actors—Client, Manager, and Owner—each managing different parts of the reservation workflow. Develop a layered Spring framework server application with Kotlin, using an in-memory H2 database and REST API architecture, documented with OpenAPI.</p>
                      <p>&nbsp;&nbsp;Created a client-side application using React with Redux and TypeScript, enabling users to search for apartments, view availability, make reservations, and manage check-in and check-out processes. It also allows owners to set availability periods and handle client reservations.</p>
                      <p>&nbsp;&nbsp;The project required extensive unit testing with JUnit and Mockito.</p>
                      <p>Main technologies:</p>
                      <ul>
                        <li>Programming Languages: Kotlin, TypeScript</li>
                        <li>Frameworks and Libraries: Spring, React, Mockito</li>
                        <li>Databases: H2</li>
                        <li>Development and Testing Tools: Postman, OpenAPI</li>
                      </ul>
                    </div>
                    <Slide
                      autoplay={true}
                      onChange={function noRefCheck() { }}
                      onStartChange={function noRefCheck() { }}
                    >
                      <div className="each-slide-effect">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}/mockups/bookings/1.png`} alt={'1'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}/mockups/bookings/2.png`} alt={'2'} />
                        </div>
                      </div>
                    </Slide>
                  </div>
                </Box>
              </Modal>
              <div className="timeline-item">
                <div className="dot-line">
                  <div className="dot" />
                </div>
                <div className="timeline-content">
                  <p className="project-name">Gym Management Project</p>
                  <p className="project-description">“My Gym”</p>
                  <div className="project-list" style={{ paddingBottom: '10px' }}>
                    <p>Development of a gym management system with functionalities including membership management, gym access, billing, scheduling, data collection, and user engagement features. Implemented using Scrum methodology, the project required SysML modeling and prototyping.</p>
                  </div>
                  <div className="button-details">
                    <Button sx={{
                      paddingTop: '10px',
                      backgroundColor: '#059669',
                      '&:hover': {
                        backgroundColor: '#064e3b',
                      },
                    }} variant="contained" onClick={handleOpenPopUpG}>View more details</Button>
                  </div>
                </div>
              </div>
              <Modal
                open={openPopUpG}
                onClose={handleClosePopUpG}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {
                      xs: '90%', // Mobile screens
                      sm: '80%', // Smaller screens
                      md: '70%', // Default
                    },
                    maxHeight: {
                      xs: '80vh',
                      sm: '85vh',
                    },
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: {
                      xs: 2,
                      sm: 3,
                      md: 4,
                    },
                    px: {
                      md: 10,
                    },
                    borderRadius: 2,
                    overflow: 'auto',
                    '& .close-button': {
                      position: 'absolute',
                      top: 8,
                      right: 8,
                    },
                  }}>
                  <IconButton
                    className="close-button"
                    aria-label="close"
                    onClick={handleClosePopUpG}>
                    <Clear />
                  </IconButton>
                  <div>
                    <p className="diss-title">MyGym</p>
                    <div className="project-list" style={{ paddingBottom: '10px' }}>
                      <p>&nbsp;&nbsp;Development of a gym management system supporting digital membership management, class scheduling, billing, data collection from machines, and user engagement features such as video-on-demand and nutrition plans.</p>
                      <p>&nbsp;&nbsp;The project used Scrum methodology and required the learning of different models, such as, BPMN, KAOS, SysML, and diagrams for use cases, architecture, logical, and process views, and a feature model to support software product line (SPL).</p>
                      <p>Main technologies:</p>
                      <ul>
                        <li>Programming Languages: JavaScript</li>
                        <li>Frameworks and Libraries: Vue</li>
                        <li>Development and Testing Tools: Postman</li>
                      </ul>
                    </div>
                    <Slide
                      autoplay={true}
                      onChange={function noRefCheck() { }}
                      onStartChange={function noRefCheck() { }}
                    >
                      <div className="each-slide-effect">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}/mockups/gym/1.png`} alt={'1'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}/mockups/gym/2.png`} alt={'2'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}/mockups/gym/3.png`} alt={'3'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}/mockups/gym/4.png`} alt={'4'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}/mockups/gym/5.png`} alt={'5'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}/mockups/gym/6.png`} alt={'6'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}/mockups/gym/7.png`} alt={'7'} />
                        </div>
                      </div>
                    </Slide>
                  </div>
                </Box>
              </Modal>
              <div className="timeline-item">
                <div className="dot-line">
                  <div className="dot" />
                </div>
                <div className="timeline-content">
                  <p className="project-name">Online Marketplace Project</p>
                  <p className="project-description">“MartList”</p>
                  <div className="project-list" style={{ paddingBottom: '10px' }}>
                    <p>Development of an online marketplace for second-hand goods and services. This project also required several prototyping and interfaces evaluation techniques, incliding paper prototyping, target market analysis, and heuristic evaluations.</p>
                  </div>
                  <div className="button-details">
                    <Button sx={{
                      paddingTop: '10px',
                      backgroundColor: '#059669',
                      '&:hover': {
                        backgroundColor: '#064e3b',
                      },
                    }} variant="contained" onClick={handleOpenPopUpM}>View more details</Button>
                  </div>
                </div>
              </div>
              <Modal
                open={openPopUpM}
                onClose={handleClosePopUpM}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {
                      xs: '90%', // Mobile screens
                      sm: '80%', // Smaller screens
                      md: '70%', // Default
                    },
                    maxHeight: {
                      xs: '80vh',
                      sm: '85vh',
                    },
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: {
                      xs: 2,
                      sm: 3,
                      md: 4,
                    },
                    px: {
                      md: 10,
                    },
                    borderRadius: 2,
                    overflow: 'auto',
                    '& .close-button': {
                      position: 'absolute',
                      top: 8,
                      right: 8,
                    },
                  }}>
                  <IconButton
                    className="close-button"
                    aria-label="close"
                    onClick={handleClosePopUpM}>
                    <Clear />
                  </IconButton>
                  <div>
                    <p className="diss-title">MartList</p>
                    <div className="project-list">
                      <p>&nbsp;&nbsp;Development of a web-based platform that enables users to buy and sell second-hand products and services.</p>
                      <p>&nbsp;&nbsp;The website facilitates seamless interactions between buyers and sellers, allowing users to list unused items in an intuitive and quick manner. The project involved usability testing and user experience optimization. Prototyping methods, including paper prototyping, were used to refine the interface, along with a target market analysis to align with user needs and heuristic evaluations to ensure usability and accessibility across the platform.</p>
                      <p>Main technologies:</p>
                      <ul>
                        <li>Programming Languages: Java, JavaScript</li>
                        <li>Frameworks and Libraries: Microsoft Azure</li>
                        <li>Development and Testing Tools: Postman</li>
                      </ul>
                    </div>
                  </div>
                </Box>
              </Modal>
              <div className="timeline-item">
                <div className="dot-line">
                  <div className="dot" />
                </div>
                <div className="timeline-content">
                  <p className="project-name">Ubiquitous Computing Project</p>
                  <p className="project-description">“DryGenius”</p>
                  <div className="project-list" style={{ paddingBottom: '10px' }}>
                    <p>Development of an ubiquitous computing project that integrates a mobile app with microcontrollers. Key components include mobile-to-hardware communication, sensor/actuator integration, and a cloud database.</p>
                  </div>
                  <div className="button-details">
                    <Button sx={{
                      paddingTop: '10px',
                      backgroundColor: '#059669',
                      '&:hover': {
                        backgroundColor: '#064e3b',
                      },
                    }} variant="contained" onClick={handleOpenPopUpU}>View more details</Button>
                  </div>
                </div>
              </div>
              <Modal
                open={openPopUpU}
                onClose={handleClosePopUpU}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {
                      xs: '90%', // Mobile screens
                      sm: '80%', // Smaller screens
                      md: '70%', // Default
                    },
                    maxHeight: {
                      xs: '80vh',
                      sm: '85vh',
                    },
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: {
                      xs: 2,
                      sm: 3,
                      md: 4,
                    },
                    px: {
                      md: 10,
                    },
                    borderRadius: 2,
                    overflow: 'auto',
                    '& .close-button': {
                      position: 'absolute',
                      top: 8,
                      right: 8,
                    },
                  }}>
                  <IconButton
                    className="close-button"
                    aria-label="close"
                    onClick={handleClosePopUpU}>
                    <Clear />
                  </IconButton>
                  <div>
                    <p className="diss-title">DryGenius</p>
                    <div className="project-list" style={{ paddingBottom: '10px' }}>
                      <p>&nbsp;&nbsp;Development of a gym management system supporting digital membership management, class scheduling, billing, data collection from machines, and user engagement features such as video-on-demand and nutrition plans.</p>
                      <p>&nbsp;&nbsp;The project consisted in the development of an IoT solution for monitoring and managing water levels and temperature within a reservoir, using an interconnected mobile app, server, and IoT device architecture. The mobile application, built with Flutter, allows users to interact with the system to view sensor data, manage settings, and switch between manual and automatic water flow modes. The Firebase server facilitates data exchange and stores user data, device information, and sensor readings.</p>
                      <p>&nbsp;&nbsp;The IoT device, an ESP32, is equipped with an ultrasonic sensor for water level detection, a DHT11 sensor for temperature and humidity monitoring, and actuators like a mini water pump and LEDs for system state indication. The system sends real-time sensor data to the server and responds to user commands for water control, offering both automation and remote access. Additionally, the mobile app provides a user interface for monitoring system status, accessing historical data, and managing user accounts and room assignments.</p>
                      <p>Main technologies:</p>
                      <ul>
                        <li>Programming Languages: Java, Arduino</li>
                        <li>Frameworks and Libraries: Flutter, ESPNOW, Google Cloud Firebase</li>
                      </ul>
                    </div>
                    <Slide
                      autoplay={true}
                      onChange={function noRefCheck() { }}
                      onStartChange={function noRefCheck() { }}
                    >
                      <div className="each-slide-effect">
                        <div>
                          <img className="mobile-image" src={`${process.env.PUBLIC_URL}/mockups/drygenius/1.png`} alt={'1'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img className="mobile-image" src={`${process.env.PUBLIC_URL}/mockups/drygenius/2.png`} alt={'2'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img className="mobile-image" src={`${process.env.PUBLIC_URL}/mockups/drygenius/3.png`} alt={'3'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img className="mobile-image" src={`${process.env.PUBLIC_URL}/mockups/drygenius/4.png`} alt={'4'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img className="mobile-image" src={`${process.env.PUBLIC_URL}/mockups/drygenius/5.png`} alt={'5'} />
                        </div>
                      </div>
                      <div className="each-slide-effect">
                        <div>
                          <img className="mobile-image" src={`${process.env.PUBLIC_URL}/mockups/drygenius/6.png`} alt={'6'} />
                        </div>
                      </div>
                    </Slide>
                  </div>
                </Box>
              </Modal>
              <div className="timeline-item">
                <div className="dot-line">
                  <div className="dot" />
                </div>
                <div className="timeline-content">
                  <p className="project-name">Auctions Management Project</p>
                  <p className="project-description">“Auctions Application”</p>
                  <div className="project-list" style={{ paddingBottom: '10px' }}>
                    <p>Development of an auctions application with bidding and Q&A features, deployed in Azure Kubernetes Service. The system utilized Redis for caching, Cosmos DB and MongoDB for data storage. Performance testing using Artillery was conducted and deployed test systems in Azure Container Instances, collecting data from multiple data centers.</p>
                  </div>
                  <div className="button-details">
                    <Button sx={{
                      paddingTop: '10px',
                      backgroundColor: '#059669',
                      '&:hover': {
                        backgroundColor: '#064e3b',
                      },
                    }} variant="contained" onClick={handleOpenPopUpAU}>View more details</Button>
                  </div>
                </div>
              </div>
              <Modal
                open={openPopUpAU}
                onClose={handleClosePopUpAU}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {
                      xs: '90%', // Mobile screens
                      sm: '80%', // Smaller screens
                      md: '70%', // Default
                    },
                    maxHeight: {
                      xs: '80vh',
                      sm: '85vh',
                    },
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: {
                      xs: 2,
                      sm: 3,
                      md: 4,
                    },
                    px: {
                      md: 10,
                    },
                    borderRadius: 2,
                    overflow: 'auto',
                    '& .close-button': {
                      position: 'absolute',
                      top: 8,
                      right: 8,
                    },
                  }}>
                  <IconButton
                    className="close-button"
                    aria-label="close"
                    onClick={handleClosePopUpAU}>
                    <Clear />
                  </IconButton>
                  <div>
                    <p className="diss-title">Auctions Application</p>
                    <div className="project-list">
                      <p>&nbsp;&nbsp;Development of a cloud-based auctions application featuring real-time bidding and Q&A functionality, with deployment in Azure Kubernetes Service. </p>
                      <p>&nbsp;&nbsp;The application included Redis caching, Cosmos DB, and MongoDB as data storage solutions, as well as a persistent volume to manage media data. Performance testing was conducted using Artillery to simulate high-load conditions. Test systems were deployed in Azure Container Instances, enabling the collection of results from clients across various data centers, showcasing the scalability and reliability of the application's infrastructure.</p>
                      <p>Main technologies:</p>
                      <ul>
                        <li>Programming Languages: Java, JavaScript</li>
                        <li>Frameworks and Libraries: Microsoft Azure, Azure Kubernetes Service, Azure Container Instances</li>
                        <li>Databases: Azure Cosmos DB, Mongo DB, Redis</li>
                        <li>Development and Testing Tools: Postman, Artillery</li>
                      </ul>
                    </div>
                  </div>
                </Box>
              </Modal>
              <div className="timeline-item">
                <div className="dot-line">
                  <div className="dot" />
                </div>
                <div className="timeline-content">
                  <p className="project-name">Design and Implementation of Secure Real-Time Streaming Protocols with UDP and TCP Project</p>
                  <p className="project-description">“RTSSP”</p>
                  <div className="project-list" style={{ paddingBottom: '10px' }}>
                    <p>Development two secure streaming protocols: RTSSP, a UDP-based real-time secure streaming protocol, and a TCP version. RTSSP ensured confidentiality, integrity, and data-origin authentication using cryptographic protection against passive and active network attacks. The TCP-based protocol added Diffie–Hellman key exchange, a keystore manager for certificate chains management, and ciphersuite selection.</p>
                  </div>
                  <div className="button-details">
                    <Button sx={{
                      paddingTop: '10px',
                      backgroundColor: '#059669',
                      '&:hover': {
                        backgroundColor: '#064e3b',
                      },
                    }} variant="contained" onClick={handleOpenPopUpRT}>View more details</Button>
                  </div>
                </div>
              </div>
              <Modal
                open={openPopUpRT}
                onClose={handleClosePopUpRT}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {
                      xs: '90%', // Mobile screens
                      sm: '80%', // Smaller screens
                      md: '70%', // Default
                    },
                    maxHeight: {
                      xs: '80vh',
                      sm: '85vh',
                    },
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: {
                      xs: 2,
                      sm: 3,
                      md: 4,
                    },
                    px: {
                      md: 10,
                    },
                    borderRadius: 2,
                    overflow: 'auto',
                    '& .close-button': {
                      position: 'absolute',
                      top: 8,
                      right: 8,
                    },
                  }}>
                  <IconButton
                    className="close-button"
                    aria-label="close"
                    onClick={handleClosePopUpRT}>
                    <Clear />
                  </IconButton>
                  <div>
                    <p className="diss-title">RTSSP</p>
                    <div className="project-list">
                      <p>&nbsp;&nbsp;Design, implementation, and evaluation of two secure real-time streaming protocols.</p>
                      <p>&nbsp;&nbsp;The first, RTSSP, utilized UDP and cryptographic protection to ensure OSI X.800 security properties, including connectionless confidentiality, integrity, and data-origin authentication. This protocol was resilient against passive and active network attacks, such as message-replay and tampering, enabling secure, real-time streaming of MP4 media from a server to a media box emulator for playback on standard players.</p>
                      <p>&nbsp;&nbsp;Building on RTSSP, the second project introduced a TCP-based protocol with enhanced security features, including Diffie–Hellman key exchange for session establishment, a Keystore Manager for managing certificates, and support for ciphersuite selection. Both protocols were experimentally evaluated for performance and security, demonstrating robust protection for real-time media streaming.</p>
                      <p>Main technologies:</p>
                      <ul>
                        <li>Programming Languages: Java</li>
                        <li>Frameworks and Libraries: Java Security</li>
                      </ul>
                    </div>
                  </div>
                </Box>
              </Modal>
              <div className="timeline-item">
                <div className="dot-line">
                  <div className="dot" />
                </div>
                <div className="timeline-content">
                  <p className="project-name">Unity-Based 3D Platform Game</p>
                  <p className="project-description">“Spoon Hunt”</p>
                  <div className="project-list" style={{ paddingBottom: '10px' }}>
                    <p>Development of a 3D platform game in Unity with dynamic platforms, enemies, obstacles, and player power-ups. Creation of a game design document outlining story, gameplay mechanics, target market, technology, and production tasks. Main techniques used involved: dynamic lights and shadows, particle systems, level of detail/LOD group, animator controllers, multiple raycasting, cinemachine, navmesh, and procedural content generation.</p>
                  </div>
                  <div className="button-details">
                    <Button sx={{
                      paddingTop: '10px',
                      backgroundColor: '#059669',
                      '&:hover': {
                        backgroundColor: '#064e3b',
                      },
                    }} variant="contained" onClick={handleOpenPopUpSH}>View more details</Button>
                  </div>
                </div>
              </div>
              <Modal
                open={openPopUpSH}
                onClose={handleClosePopUpSH}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {
                      xs: '90%', // Mobile screens
                      sm: '80%', // Smaller screens
                      md: '70%', // Default
                    },
                    maxHeight: {
                      xs: '80vh',
                      sm: '85vh',
                    },
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: {
                      xs: 2,
                      sm: 3,
                      md: 4,
                    },
                    px: {
                      md: 10,
                    },
                    borderRadius: 2,
                    overflow: 'auto',
                    '& .close-button': {
                      position: 'absolute',
                      top: 8,
                      right: 8,
                    },
                  }}>
                  <IconButton
                    className="close-button"
                    aria-label="close"
                    onClick={handleClosePopUpSH}>
                    <Clear />
                  </IconButton>
                  <div>
                    <p className="diss-title">Spoon Hunt</p>
                    <div className="project-list">
                      <p>&nbsp;&nbsp;Design and implementation of a single player 3D platform game in Unity.</p>
                      <p>&nbsp;&nbsp;The game includes dynamic and static platforms, collectible items, power-ups, NavMesh based enemies, and a GUI displaying variables such as life and points. </p>
                      <p>&nbsp;&nbsp;Also developed a detailed game design document covering various aspects, such as story, target audience, gameplay mechanics, hardware requirements, competitive analysis, art, and audio features.</p>
                      <p>Main technologies:</p>
                      <ul>
                        <li>Dynamic lights and shadows</li>
                        <li>Particle systems</li>
                        <li>Level of detail/LOD</li>
                        <li>Animator controllers</li>
                        <li>Multiple raycasting,</li>
                        <li>Cinemachine</li>
                        <li>Navmesh</li>
                        <li>Procedural content generation</li>
                      </ul>
                    </div>
                    <iframe
                      src={'https://drive.google.com/file/d/1uvAMq2Z8z1h6Zw6KApqClHPLCTdLZXxK/preview'}
                      style={{
                        position: 'absolute',
                        width: '80%',
                        height: '100%'
                      }}
                      allow="autoplay"
                      allowFullScreen
                    ></iframe>
                  </div>
                </Box>
              </Modal>
            </div>
          </section>
          <section>
            <div className="section-title">
              <h2>Skills</h2>
            </div>
            <div className="skills">
              <div className="skill">
                <span>Java</span>
                <div className="progress-bar" data-level="90"></div>
              </div>
              <div className="skill">
                <span>Kotlin</span>
                <div className="progress-bar" data-level="80"></div>
              </div>
              <div className="skill">
                <span>C/C++</span>
                <div className="progress-bar" data-level="50"></div>
              </div>
              <div className="skill">
                <span>C#</span>
                <div className="progress-bar" data-level="70"></div>
              </div>
              <div className="skill">
                <span>TypeScript</span>
                <div className="progress-bar" data-level="80"></div>
              </div>
              <div className="skill">
                <span>React</span>
                <div className="progress-bar" data-level="80"></div>
              </div>
              <div className="skill">
                <span>Google Cloud</span>
                <div className="progress-bar" data-level="70"></div>
              </div>
              <div className="skill">
                <span>Microsoft Azure</span>
                <div className="progress-bar" data-level="50"></div>
              </div>
              <div className="skill">
                <span>Arduino</span>
                <div className="progress-bar" data-level="70"></div>
              </div>
              <div className="skill">
                <span>Unity</span>
                <div className="progress-bar" data-level="60"></div>
              </div>
              <div className="skill">
                <span>SolidWorks</span>
                <div className="progress-bar" data-level="70"></div>
              </div>
            </div>
          </section>
          <section>
            <div className="section-title">
              <h2>Languages</h2>
            </div>
            <p style={{ paddingLeft: '20px', paddingTop: '15px', paddingBottom: '10px', fontSize: '18px' }}>Portuguese: Native speaker</p>
            <div style={{ paddingLeft: '20px' }}>
              <table className="language-table">
                <thead>
                  <tr>
                    <th>Language</th>
                    <th>Listening</th>
                    <th>Reading</th>
                    <th>Spoken interaction</th>
                    <th>Spoken production</th>
                    <th>Writing</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-language="English">English</td>
                    <td>C2</td>
                    <td>C2</td>
                    <td>C2</td>
                    <td>C2</td>
                    <td>C2</td>
                  </tr>
                  <tr>
                    <td data-language="Spanish">Spanish</td>
                    <td>C2</td>
                    <td>C2</td>
                    <td>C2</td>
                    <td>C2</td>
                    <td>C1</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <section>
            <div className="section-title">
              <h2>Driving Licence</h2>
            </div>
            <p style={{ paddingLeft: '20px', paddingTop: '15px', fontSize: '18px' }}>B</p>
          </section>
        </div>
      </div >
    </div >
  );
}

export default App;
